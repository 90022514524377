.App {
  /* text-align: center; */
  height: 100vh !important;
}

.menu {
  width: 100%;
  padding: 0 30px;
  display: flex;
  align-items: center;
  background: #f5f5f5f7;
  box-shadow: 1px 5px 10px #d3d3d359;
}

.menu>div {
  margin-right: 30px;
  text-decoration: none;
  cursor: pointer;
  padding: 20px 5px;
}

.content {
  width: 100%;
  /* padding: 20px; */
}
iframe {
  border: none;
  padding: 20px;
  box-sizing: border-box;
}